



























































































































import { mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { ValidationRules } from '@/api/schema';
import {
  timeSelectList,
  daySelectList,
  gameTypesList
} from '@/views/PromoView/PromoCampaignDialog/PromoCampaignFormConfiguration';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';

export default {
  name: 'PromoCampaignFormCompany',
  mixins: [PromoCampaignFormMixin],

  data(): any {
    return {
      timeSelectList,
      daySelectList,
      gameTypesList
    };
  },

  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    rules(): ValidationRules {
      return {
        cashbackTypeRadioGroup: [vr.required],
        dailyCashbackRewardInput:
          this.enabledFields.cashbackType ===
          this.EFieldTypeKey.DAILY_CASHBACK
            ? [
                ...this.positiveNumberRules,
                (v) => vr.lte(v, 100),
                (v) => vr.gte(v, 0.01),
                (v) => vr.maxPrecision(v, 2)
              ]
            : [],
        dailyCashbackTimeSelect:
          this.enabledFields.cashbackType ===
          this.EFieldTypeKey.DAILY_CASHBACK
            ? [vr.required]
            : [],
        weeklyCashbackRewardInput:
          this.enabledFields.cashbackType ===
          this.EFieldTypeKey.WEEKLY_CASHBACK
            ? [
                ...this.positiveNumberRules,
                (v) => vr.lte(v, 100),
                (v) => vr.gte(v, 0.01),
                (v) => vr.maxPrecision(v, 2)
              ]
            : [],
        dailyLimitReward:
          this.enabledFields.cashbackType ===
            this.EFieldTypeKey.DAILY_CASHBACK &&
          this.formData.dailyLimitReward
            ? [
                (v) => vr.validNumber(v, 'Value is not valid'),
                vr.positiveNumber
              ]
            : [],
        weeklyCashbackDaySelect:
          this.enabledFields.cashbackType ===
          this.EFieldTypeKey.WEEKLY_CASHBACK
            ? [vr.required]
            : [],
        weeklyCashbackTimeSelect:
          this.enabledFields.cashbackType ===
          this.EFieldTypeKey.WEEKLY_CASHBACK
            ? [vr.required]
            : [],
        weeklyLimitReward:
          this.enabledFields.cashbackType ===
            this.EFieldTypeKey.WEEKLY_CASHBACK &&
          this.formData.weeklyLimitReward
            ? [
                (v) => vr.validNumber(v, 'Value is not valid'),
                vr.positiveNumber
              ]
            : [],
        weeklyCashbackGameType:
          this.enabledFields.cashbackType ===
          this.EFieldTypeKey.WEEKLY_CASHBACK
            ? [(v) => (!!v && !!v.length) || `Value is required`]
            : [],
        dailyCashbackGameType:
          this.enabledFields.cashbackType ===
          this.EFieldTypeKey.DAILY_CASHBACK
            ? [(v) => (!!v && !!v.length) || `Value is required`]
            : []
      };
    }
  }
};
